import {ProjectInterface} from "../interface/Project.interface";
import {User} from "./User";
import {DateUtils} from "../utils/DateUtils";
import {TransactionIn} from "../interface/TransactionIn.interface";
import {ProjectLine} from "../interface/ProjectLine.interface";
import {Withdrawal} from "../interface/Withdrawal.interface";
import {Penalty} from "../interface/Penalty.interface";
import userEvent from "@testing-library/user-event";
import {UserProject} from "../interface/UserProject.interface";

export class Project implements ProjectInterface {
    uuid?: string
    participants?: number
    name: string
    status: string
    startDate: Date
    endDate: Date
    dueDay: number
    penaltyAmountPerDay: number
    penaltyPerDay: number
    activated?: boolean
    contribution: number
    cashFundAmount: number
    cashFund: number
    withdrawalAmount: number
    withdrawalDay: number
    Users: User[]
    UserProject?: UserProject
    ProjectLines: ProjectLine[]
    Withdrawals: Withdrawal[]


    constructor(params: {
        uuid?: string,
        participants?: number,
        name: string,
        startDate: Date,
        endDate: Date,
        dueDay: number,
        status: string,
        penaltyPerDay: number,
        cashFundAmount: number
        penaltyAmountPerDay: number,
        activated?: boolean,
        contribution: number,
        cashFund: number,
        withdrawalAmount: number,
        withdrawalDay: number,
        Users?: User[],
        UserProject?: UserProject,
        ProjectLines: ProjectLine[],
        Withdrawals: Withdrawal[],
    }) {
        this.name = params.name;
        this.status = params.status;
        this.participants = params.Users?.length || 0;
        this.startDate = new Date(params.startDate);
        this.penaltyPerDay = params.penaltyPerDay;
        this.cashFundAmount = params.cashFundAmount;
        this.uuid = params.uuid;
        this.endDate = new Date(params.endDate)
        this.dueDay = params.dueDay
        this.penaltyAmountPerDay = params.penaltyAmountPerDay
        this.activated = params.activated
        this.contribution = params.contribution
        this.cashFund = params.cashFund
        this.withdrawalAmount = params.withdrawalAmount
        this.withdrawalDay = params.withdrawalDay
        this.UserProject = params.UserProject || undefined
        this.ProjectLines = params.ProjectLines
            .map(it=>({
                ...it,
                TransactionIns:it.TransactionIns.map(tr=>({
                    ...tr,
                    date:new Date(tr.date!)
                }))
            }))
        this.Withdrawals = params.Withdrawals?.map((it) => ({...it, date: new Date(it.date)})) || []
        // this.TransactionIns = params.TransactionIns?.map((it) => ({...it, date: new Date(it.date!)})) || []
        // this.Penalties = params.Penalties || []
        this.Users = params.Users?.map(it=>new User({...it,role:it.role!,defaultProject:new Project(this)})) || []


    }

    computeRemainingMonthsTillProjectEnd() {
        console.log(" *** in getRemainingMonths *****")
        const currentDate = new Date()
        let remainingMonth = -1
        console.log(currentDate)
        console.log(this.endDate)
        while (currentDate < this.endDate) {
            remainingMonth++
            currentDate.setMonth(currentDate.getMonth() + 1)
        }

        console.log("***** remaining month ****")
        console.log(remainingMonth)
        return remainingMonth
    }

    computeCompletedMonthsSinceProjectStart() {
        console.log(" *** in getLeftMonths *****")
        const currentDate = new Date()
        const startDateCopy = this.startDate
        let leftMonth = 0
        console.log(currentDate)
        console.log(startDateCopy)
        while (startDateCopy <= currentDate) {
            leftMonth++
            startDateCopy.setMonth(startDateCopy.getMonth() + 1)
        }

        console.log(leftMonth)
        return leftMonth
    }

    computeCurrentMonthContributionDate() {
        console.log("***** in computeCurrentContributionDate  *********")
        const currentDate = new Date()
        let currentMonthContributionDate = new Date(currentDate)
        return new Date(currentMonthContributionDate.setDate(this.dueDay))

    }

    getCurrentMonthProjectLine() {
        console.log("*** in getCurrentMonthProjectLine ***")
        const currentDate = new Date()
        return this.ProjectLines.filter((p) =>
            p.month === currentDate.getMonth() + 1 && p.year === currentDate.getFullYear()
        )[0]

    }

    currentMonthContributionDate() {
        const currentProjectLine = this.getCurrentMonthProjectLine()
        const currentMonthTransactions =currentProjectLine.TransactionIns
        // const currentMonthTransactions = this.TransactionIns?.filter((it) => it.ProjectLineUuid === currentProjectLine.uuid)
        return currentMonthTransactions.length === 0 ? null : currentMonthTransactions[0].date

    }




    getContributionDetails() {
        console.log("*** in getContributionDetails ***")
        return this.ProjectLines.map((pl) => {
                // console.log(pl)
                // console.log(pl.TransactionIns)
                const d = new Date(`${pl.month}/01/${pl.year}`)
                // console.log(d)
                return {
                    date: d.valueOf(),
                    closedTransaction: pl.TransactionIns.filter((tr) => tr.closed).length
                }
            }
        )


    }

    getPastProjectLines = () => {
        const today = new Date()
        return this.ProjectLines
            .map((pl) => ({
                dueDate: new Date(`${pl.month}/${this.dueDay}/${pl.year}`),
                value: pl
            }))
            .filter((mappedPl) => mappedPl.dueDate < today)
            .map((mappedPl) => mappedPl.value)
    }

    getClosedTransactionsForAMonth(projectLineUuid: string) {
        const closedTransactionUuids = this.ProjectLines.filter((pl)=>pl.uuid===projectLineUuid)
            .flatMap((pl) => pl.TransactionIns)
            .filter((tr) => tr.closed)

        console.log("*** getClosedTransactionsForAMonth ***")
        console.log(new Set(closedTransactionUuids))
        return new Set(closedTransactionUuids).size
    }





    // *** for User *** //


}