export const USER_STORAGE_KEY:string="currentUser"
export const SELECTED_PROJECT_STORAGE_KEY:string="selectedProjectUuid"
export const SAVED_SELECTED_MENU_STORAGE_KEY:string="selectedMenuKey"
export const FULL_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const SHORT_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const DAYS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const ADMIN_VIEWS = {
    DASHBOARD_VIEW:1,
    PROJECT_LIST_VIEW:2,
    USER_LIST_VIEW:3,
    NEW_PROJECT_VIEW: 4,
    NEW_USER_VIEW: 5,
    NEW_PARTICIPANT_VIEW: 6,
    NEW_CASH_IN_VIEW: 7,
    NEW_CASH_OUT_VIEW: 8,
}

export const ROLES = {
    ADMIN: "ADMIN",
    USER: "USER"
}

export const defaultUser = {
    uuid: "",
    email: "",
    role: "",
    projects:[],
    isActivated:true,
    firstname: "",
    defaultProject: undefined,
    lastname: "",
    tokens: {accessToken: "", refreshToken: ""}
}
export const defaultProject = {
    uuid: "",
    status:"",
    participants: 0,
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    penaltyAmountPerDay: 0,
    penaltyPerDay: 0,
    dueDay: 0,
    activated: false,
    contribution: 0,
    withdrawalAmount: 0,
    withdrawalDay: 0,
    cashFund: 0,
    cashFundAmount: 0,
    Users: [],
    Withdrawals: [],
    Penalties: [],
    TransactionIns: [],
    ProjectLines: [],
    UserProjects: []
}
export const EMAIL_REG_EXPRESSION=/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/

//****************************************** */
// export const VERSION = "local"
// export const BASE_URL: string = "http://localhost:300O"

export const VERSION = "prod"
export const BASE_URL: string = "https://6n484y0p1i.execute-api.eu-west-3.amazonaws.com"

//********************************************************/

export const LOGIN_URL = `${BASE_URL}/${VERSION}/auth/login`
export const USER_SELF_INFO_URL = `${BASE_URL}/${VERSION}/user/project/default`
export const USER_PROJECT_URL = `${BASE_URL}/${VERSION}/user/project/me`
export const PROJECT_LIST_URL = `${BASE_URL}/${VERSION}/projects`
export const USER_LIST_URL = `${BASE_URL}/${VERSION}/users`
export const REGISTRATION_URL = `${BASE_URL}/${VERSION}/auth/register`
export const NEW_PROJECT_URL = `${BASE_URL}/${VERSION}/project`
export const UPDATE_PROJECT_URL =(projectUuid:string)=> `${BASE_URL}/${VERSION}/project/${projectUuid}`
export const UPDATE_USER_URL =(userUuid:string)=> `${BASE_URL}/${VERSION}/user/${userUuid}`
export const NEW_CASH_IN_URL = `${BASE_URL}/${VERSION}/transaction`
export const NEW_CASH_OUT_URL = `${BASE_URL}/${VERSION}/withdrawal`
export const NEW_PARTICIPANT_URL = (projectUuid:string,userUuid:string)=> `${BASE_URL}/${VERSION}/project/${projectUuid}/user/${userUuid}`

