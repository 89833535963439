import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Alert, Button, Snackbar, TableFooter, TextField} from "@mui/material";
import {AddCircle,} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {ProjectInterface} from "../../../interface/Project.interface";
import {DateCalendar, DatePicker} from "@mui/x-date-pickers";
import {NumberInput} from "@mui/base/Unstable_NumberInput/NumberInput";
import {Block} from "../../Block";
import {Project} from "../../../Models/Project";
import {ADMIN_VIEWS, defaultProject, NEW_PROJECT_URL} from "../../../utils/constant";
import dayjs from "dayjs";
import 'dayjs/locale/fr';

import useAxios from "../../../hooks/useAxios";
import {CommonContext} from "../../../contexts/CommonContext";

// Set the global locale to French
dayjs.locale('fr');

export default function NewProjectForm(props: { selectedMenu: Function }) {
    const [project, setProject] = useState<ProjectInterface>(defaultProject)
    const [globalError, setGlobalError] = useState('')
    const {setNeedToRefreshData}= useContext(CommonContext)
    const [globalInfo, setGlobalInfo] = useState('')
    const [formErrors, setFormErrors] = useState({
        name: "",
        withdrawalDay: '',
        contribution: '',
        cashFund: '',
    })

    const {postWithAxios} = useAxios()

    useEffect(() => {
        console.log(project)
        setFormErrors({
            ...formErrors,
            name: project.name === '' ? 'Ce champ est requis' : '',
            // dueDay: (isNaN(project.dueDay) || project.dueDay <= 0  || project.dueDay > 31) ? 'Valeur non valable doit être comprise entre 1-31' : '',
            withdrawalDay: (isNaN(project.withdrawalDay) || project.withdrawalDay <= 0 || project.withdrawalDay > 31) ? 'Valeur non valable, doit être comprise entre 1-31'  : '',
            // penaltyPerDay: (isNaN(project.penaltyPerDay) || project.penaltyPerDay <= 0) ? 'La valeur doit être superieure à 0' : '',
            contribution: (isNaN(project.contribution) || project.contribution <= 0) ? 'La valeur doit être superieure à 0' : '',
            cashFund: (isNaN(project.cashFund) || project.cashFund <= 0) ? 'La valeur doit être superieure à 0' : '',
        })
        console.log(formErrors)
    }, [project]);
    const createNewProject = () => {

        postWithAxios(NEW_PROJECT_URL, {
            name: project.name,
            startDate: project.startDate,
            // dueDay: project.dueDay,
            withdrawalDay: project.withdrawalDay,
            penaltyPerDay: 0,
            contribution: project.contribution,
            cashFund: project.cashFund,
        })
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo("Projet créé avec succès")
                setNeedToRefreshData(true)
                // setProject(defaultProject)

                props.selectedMenu(ADMIN_VIEWS.PROJECT_LIST_VIEW)


            }).catch((err) => {
            console.log(err)
            console.log("*** lvl 2 ***")
            console.log(err)
            setGlobalError(err.message)
        })
    }

    return (
        <div
            className={"row py-2 col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-between flex-row"}>
            {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
            <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                      message={globalInfo}></Snackbar>
            <Block title={"Nouveau projet"} borderBold={false} className={"m-4 col-sm-12 col-md-6"}>
                <form className={"d-flex flex-column "}>
                    <TextField className={"m-2"} required={true} variant="outlined" label={"Nom du projet"}
                               error={formErrors.name !== ''}
                               value={project.name}
                               helperText={formErrors.name}
                               onChange={(e) => setProject({...project, name: e.target.value})}></TextField>
                    <DatePicker className={"m-2"} label={"Date de debut"}
                                defaultValue={dayjs(project.startDate)}
                                format="DD/MM/YYYY"
                                onChange={(e: any) => setProject({...project, startDate: new Date(e)})}></DatePicker>
                    <TextField className={"m-2"} required={true} type={"number"} variant="outlined"
                               error={formErrors.withdrawalDay !== ''}
                               value={project.withdrawalDay}
                               helperText={formErrors.withdrawalDay}
                               label={"Date de retrait"}
                               onChange={(e) => setProject({...project, withdrawalDay: parseInt(e.target.value)})}></TextField>

                    <TextField className={"m-2"} required={true} variant="outlined"
                               label={"Montant de la cotisation mensuelle (en €)"}
                               error={formErrors.contribution !== ''}
                               helperText={formErrors.contribution}
                               value={project.contribution}
                               onChange={(e) => setProject({
                                   ...project,
                                   contribution: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value)
                               })}></TextField>
                    <TextField className={"m-2"} required={true} variant="outlined"
                               label={"Fond de caisse annuel (en %)"}
                               error={formErrors.cashFund !== ''}
                               helperText={formErrors.cashFund}
                               value={project.cashFund}
                               onChange={(e) => setProject({
                                   ...project,
                                   cashFund: isNaN(parseFloat(e.target.value))? 0 :parseFloat(e.target.value)
                               })}></TextField>
                    <Button onClick={() => createNewProject()}
                            className={"m-2 p-2"} variant={"outlined"}
                            color={"success"}
                            disabled={Object.values(formErrors).some((it) => it !== '')}
                            size={"small"}> Soumettre </Button>
                </form>
            </Block>
        </div>
    );
}
