import * as React from 'react';
import {Alert, Button, Snackbar, TableFooter, TextField} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import {Block} from "../../Block";
import {UserInterface} from "../../../interface/User.interface";
import {ADMIN_VIEWS, defaultUser, EMAIL_REG_EXPRESSION, LOGIN_URL, REGISTRATION_URL} from "../../../utils/constant";
import useAxios from "../../../hooks/useAxios";
import {CommonContext} from "../../../contexts/CommonContext";

export default function NewUserForm(props: { selectedMenu: Function }) {
    const [user, setUser] = useState<UserInterface>(defaultUser)
    useEffect(() => {
        // console.log(project.endDate)
    }, [user]);
    const {postWithAxios} = useAxios();
    const formRef = useRef(null)
    const [validationErrors, setValidationError] = useState({email: '', firstname: '', lastname: ''})
    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')

    const {setNeedToRefreshData} =useContext(CommonContext)

    useEffect(() => {
        setValidationError({
            firstname: user.firstname.trim() === "" ? "Ce champ est requis" : '',
            lastname: user.lastname.trim() === "" ? "Ce champ est requis" : '',
            email: (!user.email.match(EMAIL_REG_EXPRESSION) || user.email.trim() === "") ? "Email non valide" : ''
        })

        // console.log(formRef.current)
    }, [user]);
    const register = async () => {
        await postWithAxios(REGISTRATION_URL, {email: user.email, firstname: user.firstname, lastname: user.lastname})
            .then((res: any) => {
                setGlobalError("")
                setGlobalInfo("Utilisateur créé avec succès")
                setUser(defaultUser)
                setNeedToRefreshData(true)
                props.selectedMenu(ADMIN_VIEWS.USER_LIST_VIEW)
            })
            .catch((err: any) => {
                console.log("*** lvl 2 ***")
                console.log(err)
                setGlobalError(err.message)
                // else setGlobalError("Unknown error")
            })
    }
    return (
        <div
            className={"row py-2 col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-start flex-row"}>
            {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
            <p className={"text_weight_700_20_px m-2"}>Nouvel utilisateur</p>
            <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                      message={globalInfo}></Snackbar>
            <form className={"d-flex flex-column col-sm-12 col-md-6"} ref={formRef}>
                <TextField className={"m-2"} required={true} variant="outlined" label={"Nom"}
                           error={validationErrors.lastname !== ''}
                           helperText={validationErrors.lastname}
                           value={user.lastname}
                           onChange={(e) => setUser({...user, lastname: e.target.value})}
                ></TextField>
                <TextField className={"m-2"} required={true} variant="outlined" label={"Prénom(s)"}
                           error={validationErrors.firstname !== ''}
                           helperText={validationErrors.firstname}
                           value={user.firstname}
                           onChange={(e) => setUser({...user, firstname: e.target.value})}></TextField>
                <TextField className={"m-2"} required={true} variant="outlined" label={"email"}
                           error={validationErrors.email !== ''}
                           helperText={validationErrors.email}
                           value={user.email}
                           onChange={(e) => setUser({...user, email: e.target.value})}></TextField>
                <Button className={"m-2 p-2"} variant={"outlined"}
                        color={"success"}
                        disabled={Object.values(validationErrors).some(it => it !== "")}
                        onClick={register}
                        size={"small"}> Soumettre </Button>
            </form>
        </div>
    );
}
