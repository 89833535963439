
import { useContext, useEffect, useState } from "react";
import { UserProjectContext } from "../../contexts/UserProjectContext";


export function UserActiveProjectDetailsView() {

    const {selectedProject} = useContext(UserProjectContext)
    const [projectDetails, setProjectDetails] = useState<{
        label:any,
        description:string
    }[]>([])


    useEffect(() => {

        if (selectedProject?.startDate) setProjectDetails( [
            {
                label: <span className={"text_weight_700_24_px"}>{selectedProject?.UserProject?.withdrawalDate || "-"}</span>,
                description: "Ma date de collecte"
            },
            {
                label: <span className={"text_weight_700_24_px"}>{selectedProject?.startDate?.toLocaleDateString("fr")}</span>,
                description: "Date de démarrage"
            },
            {
                label: <span className={"text_weight_700_24_px"}>{selectedProject?.Users?.length}</span>,
                description: "Participants"
            },
            {
                label: <span className={"text_weight_700_24_px"}> {selectedProject?.contribution} €</span>,
                description: "Cotisation mensuelle"
            },
            {
                label: <span className={"text_weight_700_24_px"}>{selectedProject?.withdrawalAmount} €</span>,
                description: "Montant à collecter"
            },
        ])

    }, [selectedProject]);


    return <div
        className={"col-sm-12 col-md-12  bg-white d-flex  flex-column justify-content-start pt-5"}>
        {/*row 1*/}

        <p className={"text_weight_700_20_px"}>Détails du projet</p>
        <div className={"d-flex flex-column  flex-md-row justify-content-between"}>
            {projectDetails.map(it =>
                <div className={"card_detail mt-2 mt-sm-2 mt-md-0 flex-column"}>
                    <p>{it.label}</p>
                    <p>{it.description}</p>
                </div>
            )}
        </div>

    </div>
}