import { Close } from "@mui/icons-material";
import { Alert, Snackbar, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../contexts/CommonContext";
import useAxios from "../../../hooks/useAxios";
import { Project } from '../../../Models/Project';
import { UPDATE_PROJECT_URL } from "../../../utils/constant";




export default function AdminProjectEditingModal(props: { openModal: boolean, setOpenModal: Function, openedProject: Project, setOpenedProject: Function }) {

    const style = {
        position: 'absolute' as 'absolute',
        width: '500px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
    };

    const onClose = () => props.setOpenModal(false);

    const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const { setNeedToRefreshData, updateSelectedMenu } = useContext(CommonContext)

    const { putWithAxios } = useAxios()


    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')

    const [formErrors, setFormErrors] = useState({
        name: "",
        // dueDay: '',
        withdrawalDay: '',
        // penaltyPerDay: '',
        // contribution: '',
        // cashFund: '',
        // withdrawalAmount: '',
    })


    useEffect(() => {
        //console.log(props.openedProject)
        setFormErrors({
            ...formErrors,
            name: props.openedProject?.name === '' ? 'Ce champ est requis' : '',
            // dueDay: (isNaN(props.openedProject.dueDay) || props.openedProject.dueDay <= 0 || props.openedProject?.dueDay > 31) ? 'Valeur non valable doit être comprise entre 1-31' : '',
            withdrawalDay: (isNaN(props.openedProject?.withdrawalDay) || props.openedProject?.withdrawalDay <= 0 || props.openedProject?.withdrawalDay > 31) ? 'Valeur non valable, doit être comprise entre 1-31' : '',
            // penaltyPerDay: (isNaN(props.openedProject?.penaltyPerDay) || props.openedProject?.penaltyPerDay <= 0) ? 'La valeur doit être superieure à 0' : '',
            // contribution: (isNaN(props.openedProject?.contribution) || props.openedProject?.contribution <= 0) ? 'La valeur doit être superieure à 0' : '',
            // cashFund: (isNaN(props.openedProject?.cashFund) || props.openedProject?.cashFund <= 0) ? 'La valeur doit être superieure à 0' : '',
        })
        // console.log(formErrors)
    }, [props.openedProject]);
    const updateProject = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        putWithAxios(UPDATE_PROJECT_URL(props.openedProject.uuid!!), {
            name: props.openedProject?.name,
            //startDate: props.openedProject?.startDate,
            // dueDay: props.openedProject?.dueDay,
            withdrawalDay: props.openedProject?.withdrawalDay,
            //penaltyPerDay: props.openedProject?.penaltyPerDay,
            //contribution: props.openedProject?.contribution,
            //cashFund: props.openedProject?.cashFund,
        })
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo("Projet créé avec succès")
                setNeedToRefreshData(true)
                // props.setOpenedProject(defaultProject)


            }).catch((err) => {
                console.log(err)
                console.log("*** lvl 2 ***")
                console.log(err)
                setGlobalError(err.message)
            })
    }



    return (
        <Modal
            open={props.openModal}
            onClose={handleClose}
            aria-labelledby="modal-mod-title"
            aria-describedby="modal-mod-description"
        >

            <Box sx={style}
                className={"row d-flex bg-white d-flex justify-content-start col-md-12 p-3"}>
                <IconButton onClick={onClose} className={"ms-2"} style={{ width: '20px', height: '20px' }}>
                    <Close />
                </IconButton>
                {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
                <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                    message={globalInfo}></Snackbar>
                <form className={"d-flex flex-column col-12 m-1"}>
                    <p className='my-3'>{props.openedProject.name}</p>
                    <TextField className={"p-2 disabled"}  required={true} variant="outlined" label={"Nom du projet"}
                        error={formErrors.name !== ''}
                        value={props.openedProject.name}
                        helperText={formErrors.name}
                        onChange={(e) => props.setOpenedProject({ ...props.openedProject, name: e.target.value })}></TextField>
                   <TextField className={"m-2"} required={true} type={"number"} variant="outlined"
                        error={formErrors.withdrawalDay !== ''}
                        value={props.openedProject.withdrawalDay}
                        helperText={formErrors.withdrawalDay}
                        label={"Jour du mois pour les retraits"}
                        onChange={(e) => props.setOpenedProject({ ...props.openedProject, withdrawalDay: parseInt(e.target.value) })}></TextField>
                    
            

                    <div className={"d-flex justify-content-end mb-2"}>
                        <button className={"btn btn-dark mr-2"} style={{"backgroundColor":"grey", borderColor:'grey'}}
                            // onClick={() => props.selectedMenu(ADMIN_VIEWS.NEW_PARTICIPANT_VIEW)}
                            onClick={() => props.setOpenModal(false)}>
                            Annuler
                        </button>
                 
                        <button className={"btn btn-dark"} style={{ backgroundColor: "black" }}
                            disabled={Object.values(formErrors).some((it) => it !== '')}
                            onClick={updateProject}>
                            Soumettre
                        </button>
                    </div>

                </form>

            </Box>

        </Modal >
    );
}
