import React, {useContext} from "react";
import {Login} from "../pages/Login";
import {HeaderAndFooter} from "../views/HeaderAndFooter";
import {LoginContext} from "../contexts/LoginContext";

export const AuthHandler = (props: { children: React.ReactElement }) => {

    const {currentUser} = useContext(LoginContext)

    return (
        <div className={"vw-100 vh-100 min-vh-100"}>
            {currentUser?.isLogged() ? <HeaderAndFooter> {props.children} </HeaderAndFooter> : <Login/>}
        </div>

    )
}