import { useContext } from "react";
import { AdminProjectContext } from "../../../contexts/AdminProjectContext";


export function AdminActiveProjectDetailsView() {

    const {selectedProject} = useContext(AdminProjectContext)

    const projectDetailsLine1 = [

        {
            id: 5,
            label: <span><span
                className={"text_weight_700_24_px"}>{selectedProject?.penaltyPerDay}%</span> soit <span
                className={"text_weight_700_24_px"}>{selectedProject?.penaltyAmountPerDay} €/jr</span></span>,
            description: "Pénalité de retard"
        },
        {
            id: 6,
            label: <span className={"text_weight_700_24_px"}> {selectedProject?.contribution} €</span>,
            description:  <span className={"text-success"}>Cotisation</span>
        },
        {
            id: 7,
            label: <span><span className={"text_weight_700_24_px"}>{selectedProject?.cashFund}%</span> soit <span
                className={"text_weight_700_24_px"}>{selectedProject?.cashFundAmount}€</span></span>,
            description: "Font de caisse"
        },
        {
            id: 8,
            label: <span className={"text_weight_700_24_px"}> {selectedProject?.withdrawalAmount} €</span>,
            description: " Montant d’une prise"
        },
        {
            id: 9,
            label: <span className={"text_weight_700_24_px"}>{selectedProject?.participants}</span>,
            description:  <span className={"text-danger"}>Participants</span>
        }
    ]

    const projectDetailsLine2 = [
        {
            id: 1,
            label: <span
                className={"text_weight_700_24_px"}>{selectedProject ? selectedProject?.startDate.toLocaleDateString("fr") : "--"}</span>,
            description: "Date de début"
        },
        {
            id: 2,
            label: <span
                className={"text_weight_700_24_px"}>{selectedProject ? selectedProject?.endDate.toLocaleDateString("fr") : "--"}</span>,
            description: "Date de fin"
        },

        {
            id: 3,
            label: <span>
                <span className={"text_weight_700_24_px"}>{selectedProject ? selectedProject?.dueDay.toString().padStart(2,"0") : "--"}</span>
                <span>/ de chaque mois</span>
            </span>,
            description: "Date limite des paiements"
        },
        {
            id: 4,
            label: <span>
                <span
                    className={"text_weight_700_24_px"}>{selectedProject ? selectedProject?.withdrawalDay.toString().padStart(2, "0") : "--"}</span>
                <span>/ de chaque mois</span>
            </span>,
            description: "Date limite des ramassages"
        },

    ]


    return <div
        className={"col-sm-12 col-md-12 p-0  bg-white d-flex  flex-column justify-content-start pt-5"}>
        {/*row 1*/}

        <p className={"text_weight_700_20_px"}>Détails</p>
        <div className={"d-flex flex-column flex-md-row  justify-content-between"}>
            {projectDetailsLine1.map(it =>
                <div className={"card_detail mt-2 mt-md-0 mr-md-2"}
                key={it.id}
                >
                    <p>{it.label}</p>
                    <p>{it.description}</p>
                </div>
            )}
        </div>
        <div className={"d-flex flex-column flex-md-row justify-content-between"}>
            {projectDetailsLine2.map(it =>
                <div className={"card_detail mt-2 mt-md-5 mr-md-2"}
                key={it.id}
                >
                    <p>{it.label}</p>
                    <p>{it.description}</p>
                </div>
            )}
        </div>

    </div>
}