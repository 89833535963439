import {useContext, useEffect, useState} from "react";
import {AuthHandler} from "../components/AuthHandler";
import {LoginContext} from "../contexts/LoginContext";
import {AdminHome} from "../components/admin/AdminHome";
import {UserHome} from "../components/user/UserHome";


export const Home = () => {
    const {currentUser, setCurrentUser} = useContext(LoginContext)

    return (
        <AuthHandler>
                {currentUser.isAdmin() ? <AdminHome/> : <UserHome/>}
        </AuthHandler>
    )
}

