import * as React from 'react';
import {Button, List, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import {
    CreateNewFolder,
    Group, ListAlt,
    PendingActions
} from "@mui/icons-material";
import {ADMIN_VIEWS} from "../../utils/constant";

export default function AdminGlobalMenu(props: { selectedMenu: Function }) {

    function handleMenuClick(target: React.MouseEvent<HTMLElement>,value: number) {
        console.log(" *** Menu click ***")

        // @ts-ignore
        const children = target.currentTarget.parentElement.children

        for (let childrenKey in children) {
            // @ts-ignore
            children.item(childrenKey).classList.remove("active")

        }

        // @ts-ignore
        target.currentTarget.classList.add("active");
        props.selectedMenu(value)
    }

    const menuItems = [

        {
            handler: (e:React.MouseEvent<HTMLElement>) => handleMenuClick(e,ADMIN_VIEWS.DASHBOARD_VIEW),
            label: "Dashboard",
            icon: "images/water_damage_black.svg",
            isActive: true,

        },
        {
            handler: (e:React.MouseEvent<HTMLElement>) => handleMenuClick(e,ADMIN_VIEWS.PROJECT_LIST_VIEW),
            label: "Projets",
            icon: "images/grid_view.svg",
        },
        {
            handler: (e:React.MouseEvent<HTMLElement>) => handleMenuClick(e,ADMIN_VIEWS.USER_LIST_VIEW),
            label: "Utilisateurs",
            icon: "images/group_black.svg",
        }
    ]


    const menuItemView = (iconPath: string, label: string, clickHandler: Function, isActive:boolean =false) => 
    <div 
    className={`menu_item d-flex align-items-center ${isActive ? 'active' : ""}`} 
    onClick={(e)=>clickHandler(e)}
    key={`${label}`}
    
    >
        <img src={iconPath} alt={""} width={"20px"} height={"20px"}/>
        <p className={"my-0 mx-2"}> {label}</p>
    </div>

    return (
        <div
            className={"col-sm-12 col-md-12 col-lg-4 col-xl-3 d-flex flex-column justify-content-between bg-white h-100"}>

            {menuItems.map(menu => menuItemView(menu.icon, menu.label,menu.handler,menu.isActive))}


            {/*<List className={"p-0"}>*/}
            {/*    {menuItems.map((item) => {*/}
            {/*        return <ListItemButton key={item.label} onClick={item.handler}>*/}
            {/*            <ListItemIcon> {item.icon}</ListItemIcon>*/}
            {/*            <ListItemText primary={item.label} className={"fw-bold"}></ListItemText>*/}
            {/*        </ListItemButton>*/}
            {/*    })}*/}
            {/*</List>*/}


        </div>

    );


}

