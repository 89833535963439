import {createContext} from "react";
import {defaultProject} from "../utils/constant";
import {Project} from "../Models/Project";

export const UserProjectContext = createContext<{
    projects?: Project[],
    updateProjects: Function,
    selectedProject?: Project,
    updateSelectedProject: Function,

}>({
    projects: [new Project(defaultProject)],
    updateProjects: (p: Project) => {},
    selectedProject: new Project(defaultProject),
    updateSelectedProject: (p: Project) => {
    }
})