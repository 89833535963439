import { CreateNewFolder } from "@mui/icons-material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import { User } from "../../../Models/User";
import { ADMIN_VIEWS, defaultUser } from "../../../utils/constant";
import { Role } from "../../../utils/type";
import AdminUserEditingModal from '../modal/AdminUserEditingModal';

export default function GlobalUserListTable(props: { globalUserList: User[], selectedMenu: Function }) {


    const [openModal,setOpenModal]=useState<boolean>(false)
    const [openedUser,setOpenedUser]=useState<User>(new User(defaultUser))


    const data = [
        {

            description: "Effectif",
            label: <span className={"text_weight_700_24_px"}>{props.globalUserList?.length}</span>,
            classes: "text-success"
        },
        {

            description: "Actif(s)",
            label: <span className={"text_weight_700_24_px"}>{props.globalUserList.filter(it => it.isActivated).length}</span>,
            classes: "text-success"

        },
        {

            description: "Non actif(s)",
            label: <span className={"text_weight_700_24_px"}>{props.globalUserList.filter(it => !it.isActivated).length}</span>,
            classes: "text-success"

        },
    ]

    function handleOnClick(row: User) {
        console.log("*** User clicked");
        setOpenedUser(row)
        setOpenModal(true)
    }

    function handleNewViewClick(view: number) {
        return () => props.selectedMenu(view);
    }

    return (
        <div className={"row col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-between p-2"}>

            {/*stats*/}
            <p className={"text_weight_700_20_px"}>Détails</p>
            <div className={"d-flex justify-content-between col-sm-12"}>
                {data.map(it =>
                    <div className={`card_detail`}>
                        <p className={`${it.classes}`}>{it.label}</p>
                        <p>{it.description}</p>
                    </div>
                )}
            </div>
            <div className={"d-flex justify-content-end mb-2 mt-5"}>
                <button className={"btn btn-dark"} style={{ backgroundColor: "black" }}
                    onClick={handleNewViewClick(ADMIN_VIEWS.NEW_USER_VIEW)}>
                    <CreateNewFolder className={"me-1"} />
                    Nouvel Utilisateur
                </button>
            </div>

            <AdminUserEditingModal openModal={openModal} setOpenModal={setOpenModal} openedUser={openedUser} setOpenedUser={setOpenedUser}></AdminUserEditingModal>

            {/*<Button className={"d-flex col-md-3 my-2"} onClick={handleNewViewClick()}*/}
            {/*        variant={"contained"} color={"success"}>*/}
            {/*    */}
            {/*</Button>*/}

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, minHeight: 200 }} size="small" aria-label="a dense table">
                    <TableHead className={"bg-light"}>
                        <TableRow>
                            {/*<TableCell></TableCell>*/}
                            <TableCell className={"fw-bolder"} align="left">Nom</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Prenom</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Email</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Compte Actif</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Rôle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.globalUserList.map((row) => (
                            <TableRow className='m-2 cursor-pointer'
                                hover
                                onClick={(e) => handleOnClick(row)}
                                key={`${row.uuid}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {/*<TableCell></TableCell>*/}
                                <TableCell component="th" scope="row"><img src={"images/customer.png"} className={"me-1"} alt={""} width={"20px"}
                                    height={"20"} />{`${row.lastname}`}
                                </TableCell>
                                <TableCell component="th" scope="row">{`${row.firstname}`}</TableCell>
                                <TableCell component="th" scope="row">{`${row.email}`}</TableCell>
                                <TableCell component="th" scope="row">{`${row.isActivated ? "Oui" : "Non"}`}</TableCell>
                                <TableCell component="th"
                                    scope="row">{`${row.role === Role.ADMIN ? "Administrateur" : 'Utilisateur'}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
