import React from "react";
import {AuthHandler} from "../components/AuthHandler";

export function NotFound() {

    return (
        <AuthHandler>
            <div className={"row d-flex pt-5 bg-white vw-100 justify-content-center p-2"}>
                <form className={"col-sm-9 col-md-5 col-lg-4  d-flex flex-column border shadow-sm mt-5 py-3 rounded"}
                      style={{position: "relative"}}>
                    <div className={"d-flex w-100  justify-content-center"}>
                        <div className={"p-1"} style={{width: 98}}></div>
                    </div>
                    <div className={"d-flex flex-column justify-content-center text-center"}>
                        <p className={""}
                           style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "16px", fontStyle: "normal"}}>
                            Oups, cette page n'existe pas</p>

                        <p style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "28px", fontStyle: "normal"}}>
                            T.A.S</p>
                    </div>

                </form>
            </div>
        </AuthHandler>

    )
}
