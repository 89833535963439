import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {Alert, MenuItem, Select, Snackbar} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AdminProjectContext} from "../../../contexts/AdminProjectContext";
import useAxios from "../../../hooks/useAxios";
import {UserInterface} from "../../../interface/User.interface";
import {ADMIN_VIEWS, NEW_PARTICIPANT_URL} from "../../../utils/constant";
import {AdminUserContext} from "../../../contexts/AdminUserContext";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {CommonContext} from "../../../contexts/CommonContext";



export default function AdminAddProjectParticipantsModal(props:{openModel:boolean, setOpenModal:Function}) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 16,
    };

    const onClose = () => props.setOpenModal(false);

    const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const {selectedProject} = useContext(AdminProjectContext)
    const {users} = useContext(AdminUserContext)
    const {setNeedToRefreshData,updateSelectedMenu} = useContext(CommonContext)


    //console.log("**** in addNewParticipant ***",selectedProject)
    const actualProjectParticipant = selectedProject?.Users
    // console.log()
    const {putWithAxios} = useAxios()


    const nonParticipants = users
        .filter((user) => !(actualProjectParticipant?.map(it => it.uuid).includes(user.uuid)))
        .filter((it) => it.role?.toUpperCase() !== "ADMIN")

    //console.log("*** non participant : ", nonParticipants)

    const [selectedUser, setSelectedUser] = useState<UserInterface>()

    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')


    useEffect(() => {
        console.log(selectedUser)

    }, [selectedUser]);

    const addNewParticipant = () => {

        putWithAxios(NEW_PARTICIPANT_URL(selectedProject?.uuid!, selectedUser!.uuid), {})
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo(` ${selectedUser?.firstname} ajouté avec succès`)
                setNeedToRefreshData(true)
                onClose();
            }).catch((err) => {
            console.log(err)
            console.log("*** lvl 2 ***")
            setGlobalError(err.message)
        })
    }



    return (
            <Modal
                open={props.openModel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}
                    className={"d-flex py-5 bg-white d-flex justify-content-start flex-row"}>

                    {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
                    <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                              message={globalInfo}></Snackbar>
                    {selectedProject &&

                      <form className={"d-flex flex-column "}>

                        <p className={"text_weight_700_20_px"}>Nouvel Adhésion</p>
                        <p className={""}>Vous vous apprêtez à ajouter un utilisateur au project {selectedProject.name}</p>



                        <Select className={"m-2"} required={true} variant="outlined" label={"Nom"}
                                value={selectedUser?.uuid}
                                onChange={(e, newValue) => {
                                    setSelectedUser(nonParticipants.filter((user) => user.uuid === e.target.value)[0])
                                    console.log(e.target.value)
                                }}>
                            {nonParticipants.map((user) =>
                                <MenuItem key={user.uuid}
                                          value={user.uuid}>{user.lastname} {user.firstname}</MenuItem>
                            )}
                        </Select>
                        <Button className={"m-2 p-2"}
                                variant={"outlined"}
                                disabled={!selectedUser}
                                color={"success"}
                                onClick={() => addNewParticipant()}
                                size={"small"}> Ajouter </Button>
                      </form>}
                    <IconButton onClick={onClose} className={"ms-2"} style={{width:'20px', height:'20px'}}>
                        <Close />
                    </IconButton>
                </Box>

            </Modal>
    );
}
