import { TransactionIn } from "../interface/TransactionIn.interface";
import { UserInterface } from "../interface/User.interface";
import { UserProject } from "../interface/UserProject.interface";
import { ROLES } from "../utils/constant";
import { DateUtils } from "../utils/DateUtils";
import { Project } from "./Project";

export class User implements UserInterface {
    email: string;
    firstname: string;
    lastname: string;
    role?: string;
    uuid: string;
    isActivated: boolean
    tokens?: {accessToken:string,refreshToken:string}
    defaultProject?:Project
    projects:Project[]
    UserProject?:UserProject


    constructor(params:{role:string,isActivated:boolean, email:string,firstname:string,lastname:string,uuid:string,defaultProject?:Project,projects:Project[], UserProject?:UserProject,tokens?: {accessToken:string,refreshToken:string}}) {
        this.email = params.email;
        this.firstname = params.firstname;
        this.lastname = params.lastname;
        this.uuid = params.uuid;
        this.projects = params.projects;
        this.tokens= params.tokens
        this.isActivated= params.isActivated
        this.role= params.role
        this.UserProject= params.UserProject
        this.defaultProject = params.defaultProject
    }

    isLogged():boolean {
        return !!(this.tokens && this.tokens.accessToken !== "" && this.tokens.refreshToken !== "");
    }
    isAdmin():boolean {
        console.log("*** user role ***")
        console.log(this.role)
        return (this.role===ROLES.ADMIN);
    }
    toJsonString(){
        return JSON.stringify({...this})
    }

    getUserTransactionsForAMonth( projectLineUuid: string, project: Project): TransactionIn[] {

        console.log("*** in getUserTransactionsForAMonth ***")
        console.log(project)
        // console.log(this.defaultProject)
        return project.ProjectLines.filter((it) => it.uuid === projectLineUuid)[0].TransactionIns
        .filter(tr => tr.UserUuid == this.uuid) || []
    }

    hasUserClosedTransactionForAMonth(projectLineUuid: string, project: Project) {
        return this.getUserTransactionsForAMonth(projectLineUuid,project).filter((tr) => tr.closed).length > 0
    }

    getUserTotalPayedAmountForAMonth(projectLineUuid: string, project: Project) {
        console.log("*** projectLineUuid",projectLineUuid)
        const transactions = this.getUserTransactionsForAMonth(projectLineUuid, project)
        console.log("*** transactions ***")
        console.log(transactions)
        return transactions.reduce((prev, curr) => prev + (parseFloat(`${(curr.amount ?? 0)}`)), 0)
    }

    computeUserNextContributionDate( project: Project) {
        console.log("***** in computeUserNextContributionDate  *********")
        if (!this.defaultProject) return new Date()
        let currentMonthContributionDate = this.defaultProject.computeCurrentMonthContributionDate()
        const currentProjectLine = this.defaultProject.getCurrentMonthProjectLine()
        const hasUserAlreadyContribute = this.hasUserClosedTransactionForAMonth(currentProjectLine.uuid, project )
        const nextMonthContributionDate = new Date(currentMonthContributionDate.setMonth(currentMonthContributionDate.getMonth() + 1))
        if (hasUserAlreadyContribute) return nextMonthContributionDate < this.defaultProject.endDate ? nextMonthContributionDate : this.defaultProject.endDate
        return currentMonthContributionDate
    }
    computeUserCurrentMonthPenaltyDaysLeft( project: Project) {
        console.log("*** computeCurrentMonthPenaltyDaysLeftFor ***")
        const currentDate = new Date()
        const nextContributionDate = this.computeUserNextContributionDate(project)
        if (nextContributionDate && nextContributionDate < currentDate) return DateUtils.daysBetween(nextContributionDate, currentDate)
        return 0
    }

    getUserClosedTransactionsForAMonth( projectLineUuid: string) {
        return this.defaultProject?.ProjectLines.filter((it) => it.uuid === projectLineUuid)[0].TransactionIns.filter((tr) => tr.closed)  || []
    }

    getUserWithdrawals(selectedProject: Project) {
        
        return selectedProject?.Withdrawals.filter(it => it.UserUuid == this.uuid) || []
    }

    assertUserIsUpToDate(selectedProject: Project) {

        if (!this.defaultProject) return false
        const pastProjectLines = this.defaultProject?.getPastProjectLines()
        const pastUserTransactions = pastProjectLines.flatMap((pPl) => pPl.TransactionIns)
            .filter((tr) => tr.closed)
            .filter((tr) => tr.UserUuid == this.uuid)
            .map((tr) => tr.ProjectLineUuid)
        return new Set(pastUserTransactions).size >= pastProjectLines.length

    }

    getUserClosedTransactionsNumberForAMonth(user: User, projectLineUuid: string) {
        const closedTransactionUuids = this.getUserClosedTransactionsForAMonth(projectLineUuid)
            .map((tr) => tr.ProjectLineUuid)

        console.log("*** getUserClosedTransactionsNumberForAMonth ***")
        console.log(new Set(closedTransactionUuids))
        return new Set(closedTransactionUuids).size
    }

    computeMonthlyPenaltyDays( projectLineUuid: string) {
        console.log("*** in computeMonthlyPenaltyDays ***")
        if (!this.defaultProject)   return {amount:0, projectLineUuid:projectLineUuid,leftDays:0 }
        const projectLine = this.defaultProject.ProjectLines.filter((pl) => pl.uuid === projectLineUuid)[0]
        const dueDate = new Date()
        dueDate.setMonth(projectLine.month-1)
        dueDate.setDate(this.defaultProject.dueDay)
        dueDate.setFullYear(projectLine.year)
        const closedTransactions = this.getUserClosedTransactionsForAMonth(projectLineUuid)
        const fullContributionDate = (closedTransactions && closedTransactions.length > 0) ? new Date(closedTransactions[0].date!!) : new Date()
        // console.log("*******************")
        // console.log(fullContributionDate)
        // console.log(dueDate)
        const dayBetween = DateUtils.daysBetween(dueDate,fullContributionDate)
        const leftDays = dayBetween > 0 ? dayBetween:0
        // console.log(leftDays)

        return {amount:this.defaultProject.penaltyAmountPerDay * leftDays, projectLineUuid:projectLineUuid,leftDays }
    }

    computeUserTotalPenaltyDays() {
        console.log("*** in computeUserTotalPenaltyDays ***")
        console.log(this)
        if (!this.defaultProject) return undefined
        return this.defaultProject.ProjectLines.map((pl)=>{
            return this.computeMonthlyPenaltyDays(pl.uuid)
        })
    }

    generateUserActivities(){
        if (!this.defaultProject) return []
        return this.defaultProject.ProjectLines.map((pLine) => {
            const paid = this.getUserTotalPayedAmountForAMonth(pLine.uuid,this.defaultProject!)
            return {
                month: pLine.month,
                year: pLine.year,
                paid,
                remaining: this.defaultProject!.contribution - paid,
            }
        })
    }

    getNonClosedTransactionsFor(selectedProject?:Project){

        return selectedProject ? selectedProject.ProjectLines.filter(it=> it.TransactionIns.filter(tr=> tr.UserUuid == this.uuid && tr.closed ).length === 0)
        .map((p)=>({
            ...p,
            TransactionIns:p.TransactionIns.filter((tr)=>tr.UserUuid == this.uuid)
        })) : []


    }

    getTransactionsFor(selectedProject?:Project){

        return selectedProject ? selectedProject.ProjectLines.flatMap(it=> it.TransactionIns)
        .filter((tr)=>tr.UserUuid == this.uuid) : []

    }


}