import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { UserProjectContext } from "../../contexts/UserProjectContext";
import { SHORT_MONTHS } from "../../utils/constant";


export function UserActiveProjectPastContributionView() {

    const {selectedProject} = useContext(UserProjectContext)
    const {currentUser} = useContext(LoginContext)

    const [pastContributions, setPastContributions] =  useState<{
        uuid?: string,
        amount: string,
        month: string,
        date?: string
    }[]>([])




    useEffect(() => {
        const transactionsFor = currentUser.getTransactionsFor(selectedProject)

        debugger

        if (selectedProject) setPastContributions(transactionsFor.map(it =>
            ({
                uuid: it.uuid,
                amount: `${it.amount || 0 } €`,
                month:  SHORT_MONTHS[selectedProject.ProjectLines.filter( pl => pl.uuid === it.ProjectLineUuid ).at(0)!!.month -1],
                date: it?.date?.toLocaleDateString("fr")
            })));


    }, [currentUser, selectedProject]);


    return <div
        className={"col-sm-12 col-md-12  bg-white d-flex  flex-column justify-content-start mt-5"}>
        {/*row 1*/}

        <p className={"text_weight_700_20_px"}>Mes paiements passés</p>
        <div className={"d-flex justify-content-between"}>

            {pastContributions && <TableContainer component={Paper} className={"w-sm-auto w-md-75"}>
              <div style={{ overflow: "scroll"}}>
                <Table sx={{ minWidth: 300, minHeight: 200 }} size="small" aria-label="a dense table">
                  <TableHead className={"bg-light"}>
                    <TableRow>
                        {/*<TableCell></TableCell>*/}
                      <TableCell align="left" className={"text_weight_700_20_px"}>Mois</TableCell>
                      <TableCell align="left" className={"text_weight_700_20_px"}>Montant</TableCell>
                      <TableCell align="left" className={"text_weight_700_20_px"}> Date du paiement</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{pastContributions.map((row) => (
                      <TableRow
                          className={"hover-row"}
                          key={row.uuid}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                          <TableCell component="th" scope="row" className={"align-items-center"}>{`  ${row.month}`}</TableCell>
                          <TableCell component="th" scope="row">{`${row.amount}`}</TableCell>
                          <TableCell component="th" scope="row">{`${row.date}`}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>}
        </div>

    </div>
}