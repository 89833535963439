import React, {ReactElement, JSXElementConstructor, ReactNode, ReactPortal} from "react";

export function Block(props: {
    id?:string,
    title:string,
    borderBold:boolean,
    className?:string,
    style?: React.CSSProperties,
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}) {
    return (
        <div id={props.id} className={`${props.className}  border ${props.borderBold ? "border-black border-2" : "border-1" }  p-1 pt-3 my-2  rounded`} style={{...props.style, position: "relative"}}>
            <span className={"px-2"}
                style={{
                position: "absolute",
                top: -13,
                backgroundColor:"white",
                fontWeight:600,
                fontSize:16
            }}>{props.title}</span>
            {props.children}
        </div>
    );
}
