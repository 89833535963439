import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { parseInt } from "lodash";
import { useContext, useEffect, useState } from "react";
import { AdminProjectContext } from "../../../contexts/AdminProjectContext";
import { SHORT_MONTHS } from "../../../utils/constant";


export function AdminActiveProjectMonthlyDataView() {

    const { selectedProject } = useContext(AdminProjectContext)
    const [selectedProjectMonth, setSelectedProjectMonth] = useState<number>(0)


    const [projectMonthsStats, setProjectMonthsStats] = useState<
        {
            month: number,
            year: number
            remaining: number,
            collected: number
        }[]>([])

    const [projectMonthlyData, setProjectMonthlyData] = useState<
        {
            id: number,
            label: any,
            description: string,
            color?: string,
        }[]>([])




    const handleOnProjectMonthSelection = (e: SelectChangeEvent) => {
        console.log("*** handleOnProjectMonthSelection", parseInt(e.target.value))
        setSelectedProjectMonth(parseInt(e.target.value))
        // console.log("*** selectedProject : ", selectedProject)
    }

 

    useEffect(() => {
        // console.log("** SET ** ", new Set(selectedProject?.ProjectLines[0].TransactionIns.filter(tr => tr.closed).map(it=>it.UserUuid)))
        if (selectedProject) setProjectMonthsStats(selectedProject?.ProjectLines.map(it => {
            const collected = new Set(it.TransactionIns.filter(tr => tr.closed).map(it=>it.UserUuid)).size
           return {
                month: it.month,
                year: it.year,
                collected ,
                remaining: (selectedProject?.participants || 0) - collected

            }
    }))

    }, [selectedProject]);

    useEffect(() => {

        const stat = projectMonthsStats?.filter(pr =>
            pr.month === selectedProjectMonth).at(0)

        setProjectMonthlyData([
            {
                id: 1,
                label: <span className={"text_weight_700_24_px"}>{stat?.collected}</span>,
                description: "collectes effectuée(s)"
            },
            {
                id: 2,
                label: <span className={"text_weight_700_24_px"}>{stat?.remaining}</span>,
                description: "retardataire(s)",
                color: 'red'
            },
        ])

        //console.log("**** projectMonthsStats");
        if(selectedProjectMonth == 0) setSelectedProjectMonth(projectMonthsStats?.at(0)?.month || 0)

    }, [projectMonthsStats,selectedProjectMonth]);


    return <div
        className={"col-sm-12 col-md-12 pl-0 bg-white d-flex  flex-column justify-content-start pt-5"}>
        {/*row 1*/}

        <p className={"text_weight_700_20_px"}>Vue mensuelle</p>
        {selectedProject &&
            <div className={"mb-2"}>
                <Select value={selectedProjectMonth?.toString()} label={"Mois"} onChange={handleOnProjectMonthSelection} className={""}>
                    {projectMonthsStats?.map((project) =>
                        <MenuItem key={project.month.toString()} value={project.month.toString()} className={"select"}
                        >{`${SHORT_MONTHS[project.month -1]} ${project.year}`}</MenuItem>)}
                </Select>
            </div>
        }
        <div className={"d-flex col-12 p-0 justify-content-between"}>
            {projectMonthlyData?.map(it =>
                <div className={"card_detail mr-md-2"} key={it.id}>
                    <p style={{ color: it.color ? it.color : "black" }}>{it.label}</p>
                    <p>{it.description}</p>
                </div>
            )}
        </div>

    </div>
}