import {
    Alert,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AdminProjectContext } from "../../contexts/AdminProjectContext";
import { AdminUserContext } from "../../contexts/AdminUserContext";
import { CommonContext } from "../../contexts/CommonContext";
import useAxios from "../../hooks/useAxios";
import { Withdrawal } from "../../interface/Withdrawal.interface";
import {
    ADMIN_VIEWS,
    NEW_CASH_OUT_URL
} from "../../utils/constant";

export default function NewCashOutForm(props: { selectedMenu: Function }) {

    const { postWithAxios } = useAxios()
    const { selectedProject } = useContext(AdminProjectContext)
    const { selectedProjectParticipant } = useContext(AdminUserContext)
    const { setNeedToRefreshData } = useContext(CommonContext)

    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')
    const [oldWithdrawals, setOldWithdrawals] = useState<Withdrawal[]>([])
    const [withdrawalAmount, setWithdrawalAmount] = useState(selectedProject?.withdrawalAmount)
    const [comment, setComment] = useState("")
    const [formErrors, setFormErrors] = useState(
        {
            amount: '',
        })
    const [hasPenalty, setHasPenalty] = useState<boolean>(false)
    const [penalty, setPenalty] = useState<number>(0)


    const hasPanaltyCheckBoxHandlerChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasPenalty(e.target.checked)
        console.log("***", e.target);
    }

    useEffect(() => {

        if (selectedProject && selectedProjectParticipant) setOldWithdrawals(selectedProject?.Withdrawals.filter(it => it.UserUuid == selectedProjectParticipant?.uuid))

    }, [selectedProject, selectedProjectParticipant])


    useEffect(() => {
        if (selectedProject && hasPenalty) setFormErrors(
            {
                amount: ((penalty < 0) ? "Entrez un montant" :
                    (penalty > selectedProject?.withdrawalAmount ? 'Montant suppérieur au total restant à payer' : ''))

            })
    }, [selectedProject, hasPenalty]);


    useEffect(() => {
        if (selectedProject && penalty) setWithdrawalAmount(selectedProject?.withdrawalAmount - (hasPenalty ? penalty : 0))
    }, [selectedProject, hasPenalty, penalty]);



    useEffect(() => {
        if (!selectedProject) setGlobalError("Pas de projet selectionné")
        else if (!selectedProjectParticipant?.uuid) setGlobalError("Pas d'adhérant sélectionné")
        else setGlobalError("")

    }, [selectedProject, selectedProjectParticipant]);

    const addCashOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        console.log("**** in add Cash in ***")
        // console.log(props.selectedProject)

        postWithAxios(NEW_CASH_OUT_URL,
            {
                projectUuid: selectedProject!.uuid,
                userUuid: selectedProjectParticipant!.uuid,
                penalty,
                withdrawalAmount,
                comment,
            })
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo(`Décaissement éffectué avec succès`)
                setNeedToRefreshData(true)
                props.selectedMenu(ADMIN_VIEWS.DASHBOARD_VIEW)

            }).catch((err) => {
                console.log(err)
                console.log("*** lvl 2 ***")
                setGlobalError(err.message)
            })
    }

    return (
        <div
            className={"row py-2 col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-start flex-row"}>

            {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}

            <p className={"text_weight_700_20_px  m-2"}>Décaissement</p>


            <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                message={globalInfo}></Snackbar>

            {selectedProject &&

                <form className={"d-flex flex-column  col-sm-12 col-md-6"}>

                    <div className={"m-2 d-flex flex-column justify-content-between"}>
                        <span>En faveur de : <strong>{`${selectedProjectParticipant?.lastname} ${selectedProjectParticipant?.firstname}`}</strong></span>
                    </div>
                    <p className={"text_weight_700_20_px m-2 mt-4"}>Anciens décaissements</p>



                    {oldWithdrawals && <div className={"card_detail"}>
                        <TableContainer component={Paper}>
                            <div style={{ overflow: "scroll" }}>
                                <Table sx={{}} size="small" aria-label="a dense table">
                                    <TableHead className={"bg-light"}>
                                        <TableRow>
                                            {/*<TableCell></TableCell>*/}
                                            <TableCell align="left" className="fw-bolder">Date </TableCell>
                                            <TableCell align="left" className="fw-bolder"> Montant</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{oldWithdrawals.map((row) => (
                                        <TableRow
                                            hover
                                            className='cursor-pointer'
                                            key={row.uuid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {`${row.date.toLocaleDateString('fr')}`}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {`${row.amount} €`}
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </TableContainer>
                    </div>}


                    {/* </div> */}


                    <div className={"card_detail mt-4"}>
                        <div className={"m-2 d-flex flex-column justify-content-between"}>
                            <span>Montant de base brute : <strong>{`${selectedProject.withdrawalAmount} €`}</strong></span>
                        </div>

                        <div className={"m-2 d-flex flex-column justify-content-between"}>
                            <span>Font de caisse : <strong>{`${selectedProject.cashFundAmount} €`}</strong></span>
                        </div>

                        <div className={"m-2 d-flex flex-column justify-content-between"}>
                            <span>Montant net à décaisser : <strong>{`${withdrawalAmount} €`}</strong></span>
                        </div>
                    </div>

                    <div className={"m-2 mt-4 mb-0 d-flex flex-column justify-content-between"}>
                        <FormControlLabel className="" control={
                            <Checkbox checked={hasPenalty} onChange={hasPanaltyCheckBoxHandlerChanged} />
                        }
                            label="Appliquer une pénalité"
                        />
                    </div>

                    {hasPenalty && <TextField className={"ms-2 mt-1"} type={"number"}
                        label={"Montant de la pénalité"}
                        value={penalty}
                        error={formErrors.amount !== ""}
                        helperText={formErrors.amount}
                        onChange={(e) => { setPenalty((isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) < 0) ? 0 : parseFloat(e.target.value)) }}>
                    </TextField>
                    }

                    {hasPenalty && <div className={"m-2 mb-4 d-flex flex-column justify-content-between"}>
                        <span>Penalité à appliquer: <strong>{`${penalty} €`}</strong></span>
                    </div>


                    }

                    <TextareaAutosize className={"m-2"}
                        minRows={10}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value)
                            console.log(e.target.value)
                        }}>
                    </TextareaAutosize>



                    <Button className={"m-2 p-2"}
                        variant={"outlined"}
                        disabled={Object.values(formErrors).some((it) => it !== '')}
                        color={"success"}
                        onClick={(e) => addCashOut(e)}
                        size={"small"}> Valider </Button>
                </form>}
        </div>
    );
}
