import { Alert, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { User } from "../Models/User";
import { LoginContext } from "../contexts/LoginContext";
import useAxios from "../hooks/useAxios";
import { defaultUser, EMAIL_REG_EXPRESSION, LOGIN_URL } from "../utils/constant";

export function Login() {
    const { setCurrentUser} = useContext(LoginContext)
    const {postWithAxios} = useAxios()

    const [formData, setFormData] = useState({email: "", password: ""})
    const [error, setError] = useState({email: "", password: ""})
    const [globalError, setGlobalError] = useState("")


    const validateForm = () => {
        setError((prevState) => ({
            email: (formData.email.trim().length <= 0) ? "Ce champ est requis" :
                (!formData.email.match(EMAIL_REG_EXPRESSION)) ? "Email non valide" : "",
            password: formData.password.trim().length <= 0 ? "Ce champ est requis" : "",
        }))
    }

    const handleOnClick = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {type, value} = e.target
        setFormData({...formData, [type]: value})
    }


    const login = async () => {
        await postWithAxios(LOGIN_URL, {email:formData.email, secret: formData.password})
            .then((res:any) => {
                setGlobalError("")
                // console.log(user)
                setCurrentUser( new User({...res.user, tokens: res.tokens}))
                // redirect(`/`)
            })
            .catch((err:any) => {
                // console.log("***_****");
                // console.log(err);
                
                setGlobalError(err.message)
                // else  setGlobalError("Unknown error")
                setCurrentUser(new User(defaultUser))
            })
    }

    useEffect(() => {
        validateForm()
    }, [formData])

    return (
        <div className={"row d-flex pt-5 bg-white vw-100 justify-content-center p-2"}>
            <form className={"col-sm-9 col-md-5 col-lg-4  d-flex flex-column border shadow-sm mt-5 py-3 rounded"}
                  style={{position: "relative"}}>
                <div className={"d-flex w-100  justify-content-center"}>
                    <div className={"p-1"} style={{width: 98}}></div>
                    <div className={"p-1"} style={{width: 98, height: 98, position: "relative"}}>
                        <img src={"images/tas.jpeg"} width={98} height={98}
                             style={{top: "-50%", left: "-50%", position: "absolute"}} alt={""}/>
                    </div>
                </div>
                <div className={"d-flex flex-column justify-content-center text-center"}>
                    <p className={""}
                       style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "16px", fontStyle: "normal"}}>
                        Veuillez-vous connecter à votre espace personnel</p>

                    <p style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "28px", fontStyle: "normal"}}>
                        T.A.S</p>

                    {/*global error*/}
                    {globalError &&
                        <Alert severity={"error"} className={"m-2"} style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "16px",
                            fontStyle: "normal",
                            color: 'red'
                        }}>
                            {globalError}</Alert>}
                </div>


                <TextField className={"m-2"} type={"email"} required={true} variant={"outlined"}
                           label={"Email"}
                           onChange={(e) => handleOnClick(e)}
                           error={error.email !== ""}
                           helperText={error.email}
                >
                </TextField>

                <TextField className={"m-2"} type={"password"} required={true} variant={"outlined"}
                           label={"Mot de passe"}
                           onChange={(e) => handleOnClick(e)}
                           error={error.password !== ""}
                           helperText={error.password}>
                </TextField>

                <div className={"m-2"}>
                    <FormControlLabel control={<Checkbox
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            fontStyle: "normal",
                            color: "#79747E"
                        }}
                    />} label="Se souvenir de moi"/>

                </div>
                <div className={"d-flex justify-content-center"}>
                    <Button
                        className={"btn btn-sm shadow-sm col-5 rounded-5 p-1 m-3 text-white justify-content-center"}
                        disabled={Object.values(error).some(it => it !== "")}
                        onClick={login}
                        style={{backgroundColor: "#8BC34A", fontWeight: 500, fontSize: "14px"}}>Se connecter</Button>

                </div>
            </form>
        </div>
    )
}
