import { ContainerFluid } from "../components/ContainerFluid";
import { Navbar } from "../components/navbar";

export const HeaderAndFooter = (props: { children: any }) => {

    return (
        <ContainerFluid>
            <div id={"HeaderAndFooter"} className={"d-flex justify-content-between flex-column h-100"}>
                {/*header*/}

                {/*children*/}

                    <Navbar backgroundColor={"bg-white"}></Navbar>


                    {props.children}


                {/*footer*/}
                <div className={"w-100 shadow-sm bg-white p-4 text-center"}>
                    <div className={"col-sm-12 text-center"}>
                        <p>T.A.S 2024 - All rights reserved - </p>
                    </div>
                </div>
            </div>
        </ContainerFluid>

    )
}