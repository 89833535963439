import {createContext} from "react";
import { defaultUser} from "../utils/constant";
import {User} from "../Models/User";

export const AdminUserContext = createContext<{
    users: User[],
    updateUsers: Function,
    selectedProjectParticipant?: User,
    updateSelectedProjectParticipant: Function,

}>({
    users: [new User(defaultUser)],
    updateUsers: (u: User) => {},
    selectedProjectParticipant: new User(defaultUser),
    updateSelectedProjectParticipant: (u: User) => {
    }
})