import {useCallback, useState} from "react";
import {defaultUser, USER_STORAGE_KEY} from "../utils/constant";
import {User} from "../Models/User";

export default function useCurrentUser(userValue?: User) {

    const storageUser = localStorage.getItem(USER_STORAGE_KEY)
    const currentUserValue = new User(storageUser ? JSON.parse(storageUser) : defaultUser)
    const [currentUser, setCurrentUser] = useState<User>(userValue ?? currentUserValue)
    const updateCurrentUser = useCallback((value: User) => {
        console.log("*** in updateCurrentUser ***")
        console.log(value.toJsonString())
        localStorage.setItem(USER_STORAGE_KEY, value.toJsonString())
        setCurrentUser(value)
    }, [])


    return {currentUser, updateCurrentUser}
}