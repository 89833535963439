import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useContext, useMemo } from "react";
import { AdminProjectContext } from "../../contexts/AdminProjectContext";
import { SHORT_MONTHS } from "../../utils/constant";
import { AdminActiveProjectDetailsView } from "./overview/AdminActiveProjectDetailsView";
import { AdminActiveProjectMonthlyDataView } from "./overview/AdminActiveProjectMonthlyDataView";
import AdminActiveProjectUsersView from "./overview/AdminActiveProjectUsersView";


export function AdminDashboardView(props: { selectedMenu: Function, }) {

    const { projects, selectedProject, updateSelectedProject } = useContext(AdminProjectContext)
    const selectedProjectDetailMapping = useMemo(() => [
        {
            label: "Date de début",
            value: selectedProject ? `${selectedProject.startDate.getDate()} ${SHORT_MONTHS[selectedProject.startDate.getMonth()]} ${selectedProject.startDate.getFullYear()}` : "--",
            color: "#F8F9FA"
        },
        {
            label: "Date de fin",
            value: selectedProject ? `${selectedProject.endDate.getDate()} ${SHORT_MONTHS[selectedProject.endDate.getMonth()]} ${selectedProject.endDate.getFullYear()}` : "--",
            color: "#F8F9FA"
        },
        {
            label: "Participants",
            value: selectedProject ? selectedProject.participants : "--",
            color: "#F8F9FA"
        },
        {
            label: "Pénalité de retard",
            value: selectedProject ? `${selectedProject.penaltyPerDay} % soit ${selectedProject.penaltyAmountPerDay} €/jr` : "--",
            color: "#F8F9FA"
        },
        {
            label: "Cotisation",
            value: selectedProject ? `${selectedProject.contribution} €` : "--",
            color: "#F8F9FA"
        },
        {
            label: "Fond de caisse",
            value: selectedProject ? `${selectedProject.cashFund} % soit ${selectedProject.cashFundAmount} €` : "--",
            color: "#F8F9FA"
        },
        {
            label: "Montant total",
            value: selectedProject ? `${selectedProject.withdrawalAmount} €` : "--",
            color: "#DF9E24"
        },

    ], [selectedProject])


    const handleOnProjectSelection = (e: SelectChangeEvent) => {
        // console.log("*** handleOnProjectSelection", projects?.filter((p) => p.uuid === e.target.value.toString())[0])
        updateSelectedProject(projects?.filter((p) => p.uuid === e.target.value.toString())[0])
        // console.log("*** selectedProject : ", selectedProject)
    }


    return <div
        className={"row col-sm-12 col-md-12 col-lg-9 col-xl-9 bg-white d-flex justify-content-between px-4 overflow-auto "}>
        {/*row 1*/}
        {selectedProject &&
            <div className={"mb-2"}>
                <Select value={selectedProject.uuid} label={"Projet"} onChange={handleOnProjectSelection}
                    className={"text_weight_700_20_px"}>
                    {projects?.map((project) =>
                        <MenuItem key={project.uuid} value={project.uuid} className={"select"}>{project.name}</MenuItem>)}
                </Select>
            </div>
        }


        <AdminActiveProjectDetailsView />

        <div className="col-12 p-0">
            <AdminActiveProjectMonthlyDataView />
        </div>


        {/*row 2*/}
        {selectedProjectDetailMapping &&
            <div id={"row_2"} className={"col-sm-12 d-flex flex-column justify-content-lg-between mt-5 p-0"}>
                {/*col_1*/}
                <AdminActiveProjectUsersView selectedMenu={props.selectedMenu}></AdminActiveProjectUsersView>
            </div>
        }

    </div>
}