import { Close } from "@mui/icons-material";
import { Alert, Snackbar, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../contexts/CommonContext";
import useAxios from "../../../hooks/useAxios";
import { User } from '../../../Models/User';
import { EMAIL_REG_EXPRESSION, UPDATE_USER_URL } from "../../../utils/constant";




export default function AdminUserEditingModal(props: { openModal: boolean, setOpenModal: Function, openedUser: User, setOpenedUser: Function }) {

    const style = {
        position: 'absolute' as 'absolute',
        width: '500px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
    };

    const onClose = () => props.setOpenModal(false);

    const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const { setNeedToRefreshData } = useContext(CommonContext)

    const { putWithAxios } = useAxios()


    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')

    const [validationErrors, setValidationError] = useState({ email: '', firstname: '', lastname: '' })



    useEffect(() => {
        //console.log(props.openedUser)
        setValidationError({
            firstname: props.openedUser.firstname.trim() === "" ? "Ce champ est requis" : '',
            lastname: props.openedUser.lastname.trim() === "" ? "Ce champ est requis" : '',
            email: (!props.openedUser.email.match(EMAIL_REG_EXPRESSION) || props.openedUser.email.trim() === "") ? "Email non valide" : ''
        })
        //console.log(formErrors)
    }, [props.openedUser]);

    const updateUser = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        putWithAxios(UPDATE_USER_URL(props.openedUser.uuid!!), {
            firstname: props.openedUser?.firstname,
            lastname: props.openedUser?.lastname,
            email: props.openedUser?.email,
        })
            .then((res) => {
                // console.log(res)
                setGlobalError("")
                setGlobalInfo("Mise à jour éffectuée avec succès")
                setNeedToRefreshData(true)
                // props.setOpenedProject(defaultProject)

            }).catch((err) => {
                console.log(err)
                console.log("*** lvl 2 ***")
                console.log(err)
                setGlobalError(err.message)
            })
    }



    return (
        <Modal
            open={props.openModal}
            onClose={handleClose}
            aria-labelledby="modal-mod-title"
            aria-describedby="modal-mod-description"
        >

            <Box sx={style}
                className={"row d-flex bg-white d-flex justify-content-start col-md-12 p-3"}>
                <IconButton onClick={onClose} className={"ms-2"} style={{ width: '20px', height: '20px' }}>
                    <Close />
                </IconButton>
                {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
                <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                    message={globalInfo}></Snackbar>
                <form className={"d-flex flex-column col-sm-12 col-md-12"}>
                    <TextField className={"m-2"} required={true} variant="outlined" label={"Nom"}
                        error={validationErrors.lastname !== ''}
                        helperText={validationErrors.lastname}
                        value={props.openedUser.lastname}
                        onChange={(e) => props.setOpenedUser({ ...props.openedUser, lastname: e.target.value })}
                    ></TextField>
                    <TextField className={"m-2"} required={true} variant="outlined" label={"Prénom(s)"}
                        error={validationErrors.firstname !== ''}
                        helperText={validationErrors.firstname}
                        value={props.openedUser.firstname}
                        onChange={(e) => props.setOpenedUser({ ...props.openedUser, firstname: e.target.value })}></TextField>
                    <TextField className={"m-2"} required={true} variant="outlined" label={"email"}
                        error={validationErrors.email !== ''}
                        helperText={validationErrors.email}
                        value={props.openedUser.email}
                        onChange={(e) => props.setOpenedUser({ ...props.openedUser, email: e.target.value })}></TextField>


                    <div className={"d-flex justify-content-end mb-2"}>
                        <button className={"btn btn-dark me-2"} style={{ "backgroundColor": "grey", borderColor: 'grey' }}
                            // onClick={() => props.selectedMenu(ADMIN_VIEWS.NEW_PARTICIPANT_VIEW)}
                            onClick={onClose}>
                            Annuler
                        </button>

                        <button className={"btn btn-dark"} style={{ backgroundColor: "black" }}
                            disabled={Object.values(validationErrors).some(it => it !== "")}
                            onClick={updateUser}>
                            Soumettre
                        </button>
                    </div>
                </form>

            </Box>

        </Modal >
    );
}
