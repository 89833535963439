import {createContext} from "react";
import {User} from "../Models/User";
import {ADMIN_VIEWS, defaultUser} from "../utils/constant";


export const CommonContext = createContext(
    {
        needToRefreshData: true,
        setNeedToRefreshData: (v:boolean) => {},
        selectedMenu: ADMIN_VIEWS.DASHBOARD_VIEW,
        updateSelectedMenu: (v:number) => {}
    })

