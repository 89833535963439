import {createContext} from "react";
import {User} from "../Models/User";
import {defaultUser} from "../utils/constant";


export const LoginContext = createContext(
    {
            currentUser: new User(defaultUser),
            setCurrentUser: (v:User) => {}
    })

