import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import { useEffect, useMemo } from 'react';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import './App.css';
import { User } from "./Models/User";
import { LoginContext } from './contexts/LoginContext';
import useCurrentUser from "./hooks/useCurrentUser";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";

function App() {

    const {currentUser, updateCurrentUser} = useCurrentUser()


    useEffect(() => {
        // console.log("*** in App ***")
        // console.log(currentUser)
    }, [currentUser])


    const userContextValues = useMemo(() => ({
        currentUser,
        setCurrentUser: (v: User) => updateCurrentUser(v)
    }), [currentUser, updateCurrentUser])

    const browserRouter = createBrowserRouter([
        {
            path: "/",
            children: [
                {
                    path: "",
                    element: <Home/>
                },
                {
                    path: "*",
                    element: <NotFound/>
                }
            ]
        }
    ])
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <LoginContext.Provider value={userContextValues}>
                <RouterProvider router={browserRouter}/>
            </LoginContext.Provider>
        </LocalizationProvider>)
}

export default App;
